<template>
  <perseu-card :title="titleDialog">
    <template slot="content">
      <v-form ref="form" @keyup.enter.native="save">
        <v-text-field
          label="Nome"
          v-model.trim="maritalStatus.name"
          :rules="[$rules.required]"
        />
        <select-lang :rules="[$rules.required]" v-model="maritalStatus.lang" />
      </v-form>
    </template>
    <template slot="actions">
      <v-btn @click="save" color="primary">Salvar</v-btn>
    </template>
  </perseu-card>
</template>

<script>
import { save } from "@/services/marital-status-service";
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    titleDialog() {
      return (this.maritalStatus.id ? "Editar " : "Novo ") + "Estado Civil";
    },
  },
  data: function () {
    return {
      maritalStatus: this.value,
    };
  },
  watch: {
    value(value) {
      this.maritalStatus = value;
    },
  },
  methods: {
    async save() {
      if (!this.$refs.form.validate()) return;
      try {
        this.$store.dispatch("loading/openDialog");
        let { data } = await save(this.maritalStatus);
        if (this.maritalStatus.id) {
          this.$emit("update", data);
        } else {
          this.$emit("create", data);
        }
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$toasted.global.defaultError();
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
